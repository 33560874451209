import { useColorScheme, } from "react-native";
import { MD3DarkTheme, MD3LightTheme, useTheme } from "react-native-paper";

const LightThemeColors = {
    "colors": {
        "primary": "rgb(25, 96, 165)",
        "onPrimary": "rgb(255, 255, 255)",
        "primaryContainer": "rgb(212, 227, 255)",
        "onPrimaryContainer": "rgb(0, 28, 57)",
        "secondary": "rgb(3, 97, 163)",
        "onSecondary": "rgb(255, 255, 255)",
        "secondaryContainer": "rgb(209, 228, 255)",
        "onSecondaryContainer": "rgb(0, 29, 54)",
        "tertiary": "rgb(40, 94, 167)",
        "onTertiary": "rgb(255, 255, 255)",
        "tertiaryContainer": "rgb(214, 227, 255)",
        "onTertiaryContainer": "rgb(0, 27, 61)",
        "error": "rgb(186, 26, 26)",
        "onError": "rgb(255, 255, 255)",
        "errorContainer": "rgb(255, 218, 214)",
        "onErrorContainer": "rgb(65, 0, 2)",
        "background": "rgb(253, 252, 255)",
        "onBackground": "rgb(26, 28, 30)",
        "surface": "rgb(253, 252, 255)",
        "onSurface": "rgb(38 58 81)",
        "surfaceVariant": "rgb(223, 226, 235)",
        "onSurfaceVariant": "rgb(67, 71, 78)",
        "outline": "rgb(115, 119, 127)",
        "outlineVariant": "rgb(195, 198, 207)",
        "shadow": "rgb(0, 0, 0)",
        "scrim": "rgb(0, 0, 0)",
        "inverseSurface": "rgb(47, 48, 51)",
        "inverseOnSurface": "rgb(241, 240, 244)",
        "inversePrimary": "rgb(164, 201, 255)",
        "elevation": {
            "level0": "transparent",
            "level1": "rgb(242, 244, 251)",
            "level2": "rgb(235, 240, 248)",
            "level3": "rgb(228, 235, 245)",
            "level4": "rgb(226, 233, 244)",
            "level5": "rgb(221, 230, 242)"
        },
        "surfaceDisabled": "rgba(26, 28, 30, 0.12)",
        "onSurfaceDisabled": "rgba(26, 28, 30, 0.38)",
        "backdrop": "rgba(45, 49, 55, 0.4)",
        "baseBackground": "rgb(140 167 206)",
    }
};

const DarkThemeColors = {
    "colors": {
        "primary": "rgb(164, 201, 255)",
        "onPrimary": "rgb(0, 49, 93)",
        "primaryContainer": "rgb(0, 72, 131)",
        "onPrimaryContainer": "rgb(212, 227, 255)",
        "secondary": "rgb(159, 202, 255)",
        "onSecondary": "rgb(0, 50, 88)",
        "secondaryContainer": "rgb(0, 73, 125)",
        "onSecondaryContainer": "rgb(209, 228, 255)",
        "tertiary": "rgb(169, 199, 255)",
        "onTertiary": "rgb(0, 48, 99)",
        "tertiaryContainer": "rgb(0, 70, 139)",
        "onTertiaryContainer": "rgb(214, 227, 255)",
        "error": "rgb(255, 180, 171)",
        "onError": "rgb(105, 0, 5)",
        "errorContainer": "rgb(147, 0, 10)",
        "onErrorContainer": "rgb(255, 180, 171)",
        "background": "rgb(26, 28, 30)",
        "onBackground": "rgb(227, 226, 230)",
        "surface": "rgb(26, 28, 30)",
        "onSurface": "rgb(227, 226, 230)",
        "surfaceVariant": "rgb(67, 71, 78)",
        "onSurfaceVariant": "rgb(195, 198, 207)",
        "outline": "rgb(141, 145, 153)",
        "outlineVariant": "rgb(67, 71, 78)",
        "shadow": "rgb(0, 0, 0)",
        "scrim": "rgb(0, 0, 0)",
        "inverseSurface": "rgb(227, 226, 230)",
        "inverseOnSurface": "rgb(47, 48, 51)",
        "inversePrimary": "rgb(25, 96, 165)",
        "elevation": {
            "level0": "transparent",
            "level1": "rgb(33, 37, 41)",
            "level2": "rgb(37, 42, 48)",
            "level3": "rgb(41, 47, 55)",
            "level4": "rgb(43, 49, 57)",
            "level5": "rgb(45, 52, 62)"
        },
        "surfaceDisabled": "rgba(227, 226, 230, 0.12)",
        "onSurfaceDisabled": "rgba(227, 226, 230, 0.38)",
        "backdrop": "rgba(45, 49, 55, 0.4)",
        "baseBackground": "rgb(38 58 81)",
    }
};

export type AppTheme = typeof LightThemeColors;
export const useAppTheme = () => useTheme<AppTheme>();

export function GetTheme(): AppTheme {
    const colorTheme: string = useColorScheme();
    const paperTheme: AppTheme = colorTheme === "light"
        ? {...MD3LightTheme, colors: LightThemeColors.colors,}
        : {...MD3DarkTheme, colors: DarkThemeColors.colors,};
    return paperTheme;
}

