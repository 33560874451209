import { Linking } from "react-native";
import { CognitoJwtVerifier } from "aws-jwt-verify";

export class Auth {
    settings: any
    constructor(settings) {
        this.settings = settings;
    }

    async authUser() {
        const idToken = await this.getIdTokenFromUrl();
        const isValid = await this.validateUserToken(idToken);
        if (idToken === null || !isValid) { 
            window.location.href=this.settings.urls.login;
        }
        return idToken;
    }

    async getIdTokenFromUrl() {
        const url = await Linking.getInitialURL();
        const parts = new URL(url);
        const hash = parts.hash.replace('#','');
        const idToken = new URLSearchParams(hash).get('id_token');
        return idToken;
    }

    async validateUserToken(token) {
        const idVerifier = CognitoJwtVerifier.create({
            userPoolId: this.settings.cognito.userPoolId,
            tokenUse: 'id',
            clientId: this.settings.cognito.clientId,
        });
        let isValid: boolean;
        try {
            await idVerifier.verify(token);
            isValid = true;
        } catch {
            isValid = false;
        }
        return isValid
    }
}