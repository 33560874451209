import { useContext, useEffect, useState } from "react";
import { View } from "react-native";
import { settingsContext } from "../Data/Settings";
import { Button, DataTable, Divider, List, Modal, Portal, Text, TextInput } from "react-native-paper";
import { Vocab } from "../Data/Vocab";
import { VocabView } from "./AdminVocab";
import clone from "just-clone";
import { DeleteDialog, EditModal, getStyles } from "./Lib";

export const WeekView = (props) => {
    const styles = getStyles();
    const settings = useContext(settingsContext);

    const [expanded, setExpanded] = useState(false);
    const [vocabs, setVocabs] = useState<Map<String, any>>(new Map());
    const [createVisible, setCreateVisible] = useState(false);
    const showCreate = () => setCreateVisible(true);
    const hideCreate = () => setCreateVisible(false);
    const [editVisible, setEditVisible] = useState(false);
    const showEdit = () => setEditVisible(true);
    const hideEdit = () => setEditVisible(false);
    const [deleteVisible, setDeleteVisible] = useState(false);
    const showDelete = () => setDeleteVisible(true);
    const hideDelete = () => setDeleteVisible(false);
    const handlePress = () => {
        setExpanded(!expanded);
    }

    useEffect(() => {
        const getVocabs = async () => {
            const vocab = new Vocab(settings);
            const fetched = await vocab.getVocabForWeek(props.week_id);
            const newVocabs: Map<String, any> = new Map();
            fetched.forEach((v) => {
                newVocabs.set(v.vocab_id, v);
            });
            setVocabs(newVocabs);
        };
        getVocabs();
    }, [])

    const WeekEditView = (props) => {
        const styles = getStyles();
        const [topic, setTopic] = useState(props.topic)

        const cancelAction = () => {
            setTopic(props.topic);
            props.hideCallback();
        }

        const updateAction = () => {
            const updated = {
                course_id: props.course_id,
                week_id: props.week_id,
                order: props.order,
                topic: topic
            }
            props.updateCallback(updated);
            props.hideCallback();
        }
        return (
            <EditModal 
                visible={props.visible} 
                cancelAction={cancelAction} 
                saveAction={updateAction} 
                onHide={props.hideCallback}
            >
                <TextInput
                    label="Topic"
                    value={topic}
                    onChangeText={(text => setTopic(text))}
                />
            </EditModal>
        )
    }

    const WeekDeleteView = (props) => {
        const deleteAction = () => {
            const weekData = {
                course_id: props.course_id,
                week_id: props.week_id,
                topic: props.topic
            }
            props.deleteCallback(weekData);
            props.hideCallback();
        }
        const noAction = () => {
            props.hideCallback();
        }
        return (
            <DeleteDialog 
                visible={props.visible} 
                title="Delete Week" 
                verifyMessage="Are you sure you want to delete this week?" 
                deleteCallback={deleteAction} 
                onHide={props.hideCallback}
            />
        )
    }


    const deleteVocab = async (vocabData) => {
        const vocab = new Vocab(settings);
        await vocab.deleteVocabFromWeek(vocabData);
        const newVocabs = clone(vocabs);
        newVocabs.delete(vocabData.vocab_id);
        setVocabs(newVocabs);
    }

    const editVocab = async(vocabData) => {
        const vocab = new Vocab(settings);
        const updated = await vocab.updateVocab(vocabData);
        const newVocabs = clone(vocabs);
        newVocabs.set(updated.vocab_id, updated);
        setVocabs(newVocabs);
    }

    const createVocab = async(vocabData) => {
        const vocab = new Vocab(settings);
        const filteredVocab = {
            word: vocabData.word,
            gloss: vocabData.gloss,
        };
        const newVocab = await vocab.createVocab(filteredVocab);
        if (!vocabs.has(newVocab.vocab_id)) {
            const filteredVXW = {
                vocab_id: newVocab.vocab_id,
                week_id: vocabData.week_id,
                course_id: vocabData.course_id
            };
            await vocab.addVocabForWeek(filteredVXW)
        }

        const newVocabs = clone(vocabs);
        newVocabs.set(newVocab.vocab_id, newVocab);
        setVocabs(newVocabs);
    }

    const VocabList = () => {
        const vocabList = [];
        vocabs.forEach((v) => {
            vocabList.push(<VocabView key={v.vocab_id} {...v} week_id={props.week_id} course_id={props.course_id} createCallback={createVocab} updateCallback={editVocab} deleteCallback={deleteVocab}/>);
        });
        return vocabList;
    }

    const VocabCreate = (props) => {
        const [word, setWord] = useState('');
        const [gloss, setGloss] = useState('');

        const cancelAction = () => {
            setWord('');
            setGloss('');
        }

        const createAction = () => {
            const vocabData = {
                word: word,
                gloss: gloss,
                course_id: props.course_id,
                week_id: props.week_id,
            };
            props.createCallback(vocabData);
        }
        return (
            <EditModal 
                visible={props.visible} 
                cancelAction={cancelAction} 
                saveAction={createAction} 
                onHide={props.hideCallback} 
                warning="If the Word already exists in another course/week, we will use the existing gloss and not what is entered here."
            >
                <TextInput
                    label="Word"
                    value={word}
                    onChangeText={(text => setWord(text))}
                />
                <TextInput
                    label="Gloss"
                    value={gloss}
                    onChangeText={(text => setGloss(text))}
                />
            </EditModal>
        )
    }

    return (
        <List.Accordion
            title={props.topic}
            left={props => <List.Icon style={{margin: 0}} {...props} icon="calendar-range-outline" />}
            expanded={expanded}
            onPress={handlePress}
            style={{flex: .9, minWidth: '90%', margin: 0, padding: 0}}
        >
            <View style={{flex: 1}}>
                <Portal>
                    <WeekEditView {...props} visible={editVisible} hideCallback={hideEdit} updateCallback={props.updateCallback} />
                    <WeekDeleteView {...props} visible={deleteVisible} hideCallback={hideDelete} deleteCallback={props.deleteCallback} />
                    <VocabCreate {...props} visible={createVisible} hideCallback={hideCreate} createCallback={createVocab} />
                </Portal>
                <View style={{flex:1}}>
                    <View style={{margin: 0, flexDirection: "row" }}>
                        <Button {...props} style={styles.topButtons} size={16} mode="text" icon="file-document-edit-outline" onPress={showEdit}>Edit Week</Button>
                        <Button {...props} style={styles.topButtons} size={16} mode="text" icon="delete-forever-outline" onPress={showDelete}>Delete Week</Button>
                        <Button {...props} style={styles.topButtons} size={16} mode="text" icon="card-plus-outline" onPress={showCreate}>New Vocab</Button>
                    </View>
                    <Divider />
                    <DataTable>
                        <DataTable.Header>
                            <DataTable.Title>Word</DataTable.Title>
                            <DataTable.Title>Gloss</DataTable.Title>
                            <DataTable.Title>Delete</DataTable.Title>
                        </DataTable.Header>
                        <VocabList />
                    </DataTable>
                </View>

            </View>

        </List.Accordion>
    )

}
