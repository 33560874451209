import { Auth } from "./Auth";

export class Week {
    settings: any
    auth: Auth
    idToken: Promise<string>

    constructor(settings) {
        this.settings = settings;
        this.auth = new Auth(this.settings);
        this.idToken = this.auth.authUser()
    }

    async getWeeksForCourse(courseId) {
        const weeks = await fetch(`${this.settings.urls.api}/course/${courseId}/week`, {method: 'GET', headers: {Authorization: await this.idToken}});
        const weeksJs = await weeks.json();
        return weeksJs.weeks;
    }

    async createWeek(week) {
        const requestBody = {topic: week.topic};
        if ('order' in week) requestBody['order'] = week.order;
        const reqUrl = `${this.settings.urls.api}/course/${week.course_id}/week`;
        const headers = {Authorization: await this.idToken, 'Content-Type': "application/json"};
        const newWeek = await fetch(reqUrl, {method: 'POST', headers: headers, body: JSON.stringify(requestBody)})
        return await newWeek.json();
    }

    async updateWeek(week) {
        const requestBody = {};
        const validKeys = ['order', 'topic'];
        validKeys.forEach((key) => {
            if (key in week) requestBody[key] = week[key];
        });
        const reqUrl = `${this.settings.urls.api}/course/${week.course_id}/week/${week.week_id}`;
        const headers = {Authorization: await this.idToken, 'Content-Type': "application/json"};
        const updatedCourse = await fetch(reqUrl, {method: 'PUT', headers: headers, body: JSON.stringify(requestBody)})
        return await updatedCourse.json();
    }

    async deleteWeek(week) {
        const reqUrl = `${this.settings.urls.api}/course/${week.course_id}/week/${week.week_id}`;
        const headers = {Authorization: await this.idToken, 'Content-Type': "application/json"};
        await fetch(reqUrl, {method: 'DELETE', headers: headers})
    }
}