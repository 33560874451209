import { useState } from "react";
import { ScrollView, StyleSheet, View } from "react-native";
import { useAppTheme } from "./Themes";
import { Button, Card, DataTable, Dialog, Divider, IconButton, List, Modal, Portal, Text, TextInput } from "react-native-paper";
import { DeleteDialog, EditModal, getStyles } from "./Lib";

export const VocabView = (props) => {
    const styles = getStyles();

    const [deleteVisible, setDeleteVisible] = useState(false);
    const showDelete = () => setDeleteVisible(true);
    const hideDelete = () => setDeleteVisible(false);

    const [editVisible, setEditVisible] = useState(false);
    const showEdit = () => setEditVisible(true);
    const hideEdit = () => setEditVisible(false);

    const VocabDeleteView = (props) => {
        const deleteAction = () => {
            const vocabData = {
                vocab_id: props.vocab_id,
                week_id: props.week_id,
            }
            props.deleteCallback(vocabData);
            props.hideCallback();
        }
        return (
            <DeleteDialog 
                visible={props.visible} 
                title="Delete Vocab" 
                verifyMessage="Are you sure you want to remove this vocab word from this week?" 
                deleteCallback={deleteAction} 
                onHide={props.hideCallback}
            />
        )
    }

    const VocabEdit = (props) => {
        const styles = getStyles();
        const [vocabId, setVocabId] = useState(props.vocab_id);
        const [word, setWord] = useState(props.word);
        const [gloss, setGloss] = useState(props.gloss);

    
        const cancelAction = () => {
            setVocabId(props.vocab_id);
            setWord(props.word);
            setGloss(props.gloss);
            props.hideCallback();
        }
    
        const updateAction = () => {
            const updated = {
                vocab_id: vocabId,
                word: word,
                gloss: gloss
            }
            props.updateCallback(updated);
            props.hideCallback();
        }
        return (
            <EditModal 
                visible={props.visible} 
                cancelAction={cancelAction} 
                saveAction={updateAction} 
                onHide={props.hideCallback} 
                warning="If you edit either the Word or Gloss values here, it will be changed for any week that uses this word."
            >
                <TextInput
                    label="Word"
                    value={word}
                    onChangeText={(text => setWord(text))}
                />
                <TextInput
                    label="Gloss"
                    value={gloss}
                    onChangeText={(text => setGloss(text))}
                />
            </EditModal>
        )
    }

    return (
        <DataTable.Row key={props.vocab_id}>
            <Portal>
                <VocabDeleteView {...props} visible={deleteVisible} hideCallback={hideDelete} deleteCallback={props.deleteCallback} />
                <VocabEdit {...props} visible={editVisible} hideCallback={hideEdit} updateCallback={props.updateCallback} />
            </Portal>
            <DataTable.Cell onPress={showEdit}>{props.word}</DataTable.Cell>
            <DataTable.Cell onPress={showEdit}>{props.gloss}</DataTable.Cell>
            <DataTable.Cell><IconButton {...props} style={styles.topButtons} size={16} mode="text" icon="delete-forever-outline" onPress={showDelete}></IconButton></DataTable.Cell>
        </DataTable.Row>     
    )
}
