import { View, StyleSheet } from "react-native"
import { Appbar } from "react-native-paper"
import {
    Tabs,
    TabScreen,
    useTabIndex,
    useTabNavigation,
    TabsProvider
  } from 'react-native-paper-tabs';
import { NavBarBase } from "../Components/Navbar"
import { useAppTheme } from "../Components/Themes";

import { AdminCourse } from "../Components/AdminCourse";
import { Lexicon } from "../Components/AdminLexicon";
import { Enrollment } from "../Components/AdminEnrollment";
import { AdminPanel } from "../Components/Lib";

export const Admin = (props) => {
    const styles = getStyles();
    return (
      <View style={styles.backgroundContainer}>
        <NavBarBase>
            <Appbar.Action icon="backspace-outline" onPress={() => {props.navigation.navigate("Kairos Vocab")}}/>
        </NavBarBase>
        <TabsProvider defaultIndex={0}>
            <Tabs>
                <TabScreen label="Student Enrollment" icon="school">
                    <AdminPanel>
                        <Enrollment />
                    </AdminPanel>
                </TabScreen>
                <TabScreen label="Course Material" icon="book">
                    <AdminPanel>
                        <AdminCourse />
                    </AdminPanel>
                </TabScreen>
                {/*
                <TabScreen label="Lexicon" icon="library">
                    <AdminPanel>
                        <Lexicon />
                    </AdminPanel>
                </TabScreen>
                */}
            </Tabs>

        </TabsProvider>
        
      </View>
    )
  }

function getStyles() {
    const theme = useAppTheme();
    return StyleSheet.create({
        backgroundContainer: {
            flex: 1,
            backgroundColor: theme.colors.baseBackground,
        },
    });
}