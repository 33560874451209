import React, { useState } from 'react';
import { NavigationContainer } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack'

import { StyleSheet, SafeAreaView, View } from 'react-native';
import { PaperProvider, Text } from 'react-native-paper';

import { GetTheme } from './Components/Themes';
import { AppTheme } from './Components/Themes';

import { SettingsProvider } from './Data/Settings';
import { Main } from './Views/Main';
import { Admin } from './Views/Admin';


const Stack = createNativeStackNavigator();


export default function App() : JSX.Element {
  const theme: AppTheme = GetTheme();

  return (
    <SettingsProvider>
      <SafeAreaView style={styles.container}>
        <PaperProvider theme={theme}>
          <NavigationContainer>
          <Stack.Navigator initialRouteName="Kairos Vocab">
            <Stack.Screen name="Kairos Vocab" component={Main} options={{ headerShown: false }} />
            <Stack.Screen name="Admin" component={Admin} options={{ headerShown: false }} />
          </Stack.Navigator>
          </NavigationContainer>
        </PaperProvider>
      </SafeAreaView>
    </SettingsProvider>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    overflow: 'hidden',
  },
});


