import clone from "just-clone";
import { useState } from "react";
import { View } from "react-native";
import { NavBarWithCourses } from "../Components/Navbar";
import { VocabApp } from "../Components/VocabCard";

export const Main = (props) => {
    const [activeWeeks, setActiveWeeks] = useState(null);
  
    const toggleWeek = (week_id) => {
      const newWeeks = clone(activeWeeks);
      const index = newWeeks.indexOf(week_id);
      let isActive;
  
      if (index === -1) {
        newWeeks.push(week_id);
        isActive = true;
      } else {
        newWeeks.splice(index, 1);
        isActive = false;
      }
      setActiveWeeks(newWeeks);
    }
    return (
      <View style={{flex: 1}}>
        <NavBarWithCourses weekCallback={toggleWeek} setWeeksCallback={setActiveWeeks} navigation={props.navigation}/>
        {activeWeeks && <VocabApp activeWeeks={activeWeeks} />}
      </View>
    )
  }