import { useState, useEffect, useContext } from "react";
import { View, StyleSheet, Dimensions, Linking } from "react-native";
import { ActivityIndicator, Button, Modal, Surface, Text } from "react-native-paper";
import * as fsrsjs from 'fsrs.js'
import clone from 'just-clone';

import FlipCard from 'react-native-flip-card';
import { useAppTheme } from "./Themes";
import { VariantProp } from "react-native-paper/lib/typescript/components/Typography/types";
import { Vocab } from "../Data/Vocab";
import { settingsContext } from "../Data/Settings";
import { User } from "../Data/User";

const fsrs = new fsrsjs.FSRS;

class VocabCard extends fsrsjs.Card {
    student_id: string
    vocab_id: string
    word: string
    gloss: string
    weeks: Array<any>
    constructor(props) {
        super();
        const {student_id, vocab_id, frontWord, backWord, weeks} = props;
        this.word = frontWord;
        this.gloss = backWord;
        this.vocab_id = vocab_id;
        this.student_id = student_id;
        this.weeks = weeks;
    }
    static create(props) {
        return new this(props); 
      }
}

export type VocabCardProps = {
    card: VocabCard
}

type VocabCardFaceProps = {
    orientation: Orientations,
    text: string,
}
function makeMotivation(ready, done) {
    let motivation: string;
    if (ready === 0) {
        motivation = "All done! Keep reviewing for bonus points.";
    } else if (done === 0) {
        motivation = "Let's do this!";
    } else if (ready / (ready + done) >= .70) {
        motivation = "You can do it!";
    } else if (ready / (ready + done) >= .35) {
        motivation = "You're almost there!"
    } else {
        motivation = "You're at the finish line!"
    }
    return motivation;
}

function getStatuses(cards) {
    const now = Date.now();
    const nowPlus = new Date(now + 300000); //300000 == 5min
    let readyCards = 0;
    let doneCards = 0;
    cards.forEach(card => {
        if (card.due <= nowPlus) {
            readyCards++;
        } else {
            doneCards++;
        }
    });
    const motivation = makeMotivation(readyCards, doneCards);
    return {ready: readyCards, done: doneCards, motivation: motivation};
}

function compareCards(c1: VocabCard, c2: VocabCard): number {
    const c1Date: Date = new Date(c1.due);
    const c2Date: Date = new Date(c2.due);
    return c1Date.getTime() - c2Date.getTime()
};
export type VocabAppProps = {
    activeWeeks: Array<String>,
}

export function VocabApp(props: VocabAppProps): JSX.Element {
    const settings = useContext(settingsContext);
    
    const {width, height} = Dimensions.get("window");
    const [orientation, setOrientation] = useState(getOrientation(width, height));    
    useEffect(() => {
        const subscription = Dimensions.addEventListener(
            'change',
            ({window}) => {
            setOrientation(getOrientation(window.width, window.height));
            },
        );
        return () => subscription?.remove();
    },[]);

    const [cardStatuses, setCardStatuses] = useState(null);

    const [deck, setDeck] = useState<VocabCard[]>(null);
    const [cards, setCards] = useState<Map<String, VocabCard>>(new Map());

    const [ready, setReady] = useState(false);

    let intervalId;
    useEffect(() => {
        const fetchCards = async () => {
            const user = new User(settings);
            const uInfo = await user.aboutMe();
            if (!uInfo.is_active) return;

            const newCards: Map<String, any> = new Map();
            const vocab = new Vocab(settings);
            const vocabCards = await vocab.getVocabCards();
            vocabCards.forEach((card: VocabCard) => newCards.set(card.word, card));
            await setCards(newCards);
            await buildDeck(newCards);
            setReady(true);
            clearInterval(intervalId);
        };  
        intervalId = setInterval(fetchCards, 5000);
        fetchCards();
        return () => clearInterval(intervalId);

    }, []);
    
    const updateCards = async (card: VocabCard) => {
        const newCards = clone(cards);
        newCards.set(card.word, card);
        setCards(newCards);
    };

    const buildDeck = (useCards = null) => {
        const enabledCards: Array<any> = [];
        if (useCards === null) useCards = cards;
        useCards.forEach((card) => {
            card.weeks.some((week) => {
                if (props.activeWeeks.includes(week)) {
                    enabledCards.push(card);
                    return true;
                } else {
                    return false;
                }
            });
           })
        const statuses = getStatuses(enabledCards);
        setCardStatuses(statuses);
        enabledCards.sort(compareCards);
        setDeck(enabledCards);
    } 

    useEffect(() => {  
        buildDeck();
    }, [props.activeWeeks]);

    const styles = getStyles();
    const orientationStyles = getOrientationStyles(styles);

    const [lastViewed, setLastViewed] = useState(new Date());

    const cardButtonPress = async (gradeStatus: fsrsjs.Rating) => {
        const newDeck = [...deck];
        const topCard = newDeck.shift();
        const scheduling_cards = fsrs.repeat(topCard, lastViewed);
        const newCard = scheduling_cards[gradeStatus].card as VocabCard;
        const vocab = new Vocab(settings);
        vocab.updateCard(newCard);
        updateCards(newCard);

        newDeck.push(newCard);
        const statuses = await getStatuses(newDeck);
        const threshold = Math.min(newDeck.length * .3, 4);
        if (statuses.ready >= threshold) {
            await newDeck.sort(compareCards);
        }
        setDeck(newDeck);
        setCardStatuses(statuses);
        setLastViewed(new Date());
    }

    useEffect(() => {
    }, [deck])

    if (!ready) {
        return <View style={{flex: 1, justifyContent: "center", alignItems: "center"}}><ActivityIndicator size='large'/></View>
    } else if (deck.length === 0) {
        const LinkText = (): JSX.Element => {return <Text style={{color: 'blue'}} onPress={() => Linking.openURL('https://kairosclassroom.com/classes')}>kairosclassroom.com/courses</Text>}
        return (
            <View style={styles.backgroundContainer}>
                <Modal visible={true} dismissable={false} contentContainerStyle={[styles.cardFaceContainerBase, {padding: 60, margin: 100}]}>
                    <Text variant="headlineMedium" style={{paddingBottom: 40}}>
                        Please enroll in classes at {<LinkText />} to use this vocab app.
                    </Text>
                    <Text variant="headlineSmall">
                        Already enrolled in a course? Check the Settings (gear icon) and toggle on some weeks.
                    </Text>
                </Modal>
            </View>);
    }
    const existingCard = (
        <View style={orientationStyles[orientation].buttonsContainer}>
            <VocabCardButton {...{icon: "emoticon-outline", text: "got it!", onPressFunc: () => {cardButtonPress(fsrsjs.Rating.Good);}}} />
            <VocabCardButton {...{icon: "emoticon-confused-outline", text: "struggled", onPressFunc: () => {cardButtonPress(fsrsjs.Rating.Hard);}}} />
            <VocabCardButton {...{icon: "emoticon-frown-outline", text: "missed it", isError: true, onPressFunc: () => {cardButtonPress(fsrsjs.Rating.Again);}}} />
        </View>
    );
    const newCard = (
        <View style={orientationStyles[orientation].buttonsContainer}>
                <VocabCardButton {...{icon: "emoticon-outline", text: "Next", onPressFunc: () => {cardButtonPress(fsrsjs.Rating.Again);}}} />
        </View>
    );
    return (
        <View style={styles.backgroundContainer}>
            <View style={orientationStyles[orientation].cardDirection}>
                <FlipCard flipHorizontal={true} flipVertical={false} perspective={10000} friction={10} key={new Date().getTime().toString()}>
                    <VocabCardFace {...{text: deck[0].word, orientation: orientation}} />
                    <VocabCardFace {...{text: deck[0].gloss, orientation: orientation}} />
                </FlipCard>
                {
                    deck[0].state == 0 && newCard || existingCard
                }

            </View>
            <View style={[styles.statsContainerBase, orientationStyles[orientation].statsContainer]}>
                <Text style={styles.statsText} variant="headlineMedium">Learned {'\n'} {cardStatuses.done}</Text>
                <Text style={[styles.statsText, {fontStyle: "italic"}]} variant="headlineSmall">{cardStatuses.motivation}</Text>
                <Text style={styles.statsText} variant="headlineMedium">Remaining {'\n'} {cardStatuses.ready}</Text>
            </View>
        </View>
    )
};

function VocabCardButton(props): JSX.Element {
    const theme = useAppTheme();
    const buttonBackgroundColor = theme.colors.elevation.level1;
    const textColor = theme.colors.primary;
    const failTextColor = theme.colors.onSurfaceVariant;

    const buttonColor = props.isError ? failTextColor : textColor;

    return (
        <Button contentStyle={{justifyContent: "flex-start"}} labelStyle={{fontSize: 40 }} style={{width: 200}} buttonColor={buttonBackgroundColor} textColor={textColor} icon={props.icon} mode="elevated" onPress={props.onPressFunc}>
            <Text theme={{colors: { onSurface: textColor}}} variant="headlineSmall">{props.text}</Text>
        </Button>
    )
}

function VocabCardFace(props: VocabCardFaceProps): JSX.Element {
    const screenDimensions = Dimensions.get("screen");
    const initialWindow = Dimensions.get("window");
    const initialSize = getFontVariant(initialWindow.width, screenDimensions.width)
    const [fontVariant, setFontVariant] = useState(initialSize);

    useEffect(() => {
        const subscription = Dimensions.addEventListener(
          'change',
          ({window}) => {
            setFontVariant(getFontVariant(window.width, screenDimensions.width))
          },
        );
        return () => subscription?.remove();
      });
    const styles = getStyles();
    const orientationStyle = props.orientation === Orientations.PORTRAIT ? 
        styles.cardFaceContainerPortrait : 
        styles.cardFaceContainerLandscape;
    return (
        <Surface style={[styles.cardFaceContainerBase, orientationStyle]} elevation={1}>
            {fontVariant && <Text variant={fontVariant as VariantProp<string>}>{props.text}</Text>}
        </Surface>
    )
};


//Style nonsense
enum Orientations {
    PORTRAIT = 'PORTRAIT',
    LANDSCAPE = 'LANDSCAPE',
};

function getOrientation(width, height){
    return height >= width ? Orientations.PORTRAIT : Orientations.LANDSCAPE
};

function getOrientationStyles(styles){
    const orientationStyles = {
        PORTRAIT: {
            cardDirection: styles.cardContainerPortrait,
            buttonsContainer: styles.buttonsContainerPortrait,
            buttons: styles.buttonsPortrait,
            buttonSize: 40,
            statsContainer: styles.statsContainerPortrait,
        },
        LANDSCAPE: {
            cardDirection: styles.cardContainerLandscape,
            buttonsContainer: styles.buttonsContainerLandscape,
            buttons: styles.buttonsLandscape,
            buttonSize: 60,
            statsContainer: styles.statsContainerLandscape
        }
    };
    return orientationStyles;
}

function getFontVariant(width, maxWidth) {
    if (width >= maxWidth * .6) {
        return "displayLarge";
    }
    else if (width >= maxWidth * .35) {
        return "displayMedium";
    } else {
        return "displaySmall";
    }
}

function getStyles() {
    const theme = useAppTheme();
    return StyleSheet.create({
    backgroundContainer: {
        flex: 1,
        backgroundColor: theme.colors.baseBackground,
    },
    cardFaceContainerBase: {
        alignItems:"center",
        justifyContent:"center",
        borderRadius: 20,
        backgroundColor: theme.colors.surface,
    },
    cardFaceContainerPortrait: {
        flex: .6,
        margin: 40

    },
    cardFaceContainerLandscape: {
        flex: 1,
        margin: 40,
        marginRight: 60,

    },
    statsContainerBase: {
        flexDirection: 'row-reverse', 
        alignItems: 'center', 
        justifyContent: 'space-around', 
        backgroundColor: theme.colors.surface,
    },
    statsContainerPortrait: {
        flex: .15, 
    },
    statsContainerLandscape: {
        flex: .25,  
    },
    statsText: {
        flex: .25, 
        textAlign: 'center',
    },
    buttonsContainerPortrait: {
        flex: .2, 
        flexDirection: 'row-reverse',
        justifyContent: "space-evenly",
        alignItems: "flex-start",
    },
    buttonsContainerLandscape: {
        flex: .13,
        justifyContent: "space-evenly",
        marginRight: 100,
        alignItems: "flex-start",
    },
    buttonsPortrait: {
        flex: .5, 
        marginHorizontal: 20,
    },
    buttonsLandscape: {
        flex: 1,
        alignItems: "flex-start",
        width: '100%'
    },
    cardContainerPortrait: {
        flex: 1,
        flexDirection: 'column',
    },
    cardContainerLandscape: {
        flex: 1,
        flexDirection: 'row',
    }
  });
};