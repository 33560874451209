import { createContext, useEffect, useState } from "react";
import { ActivityIndicator, Linking, View, Image } from "react-native";
// @ts-ignore
import {STAGE} from "@env";


const getWebroot = () => {
    let webroot;
    if (STAGE.toLowerCase() === 'prod') {
        webroot = "kairosvocab.com";
    } else {
        webroot = `${STAGE}.kairosvocab.com`;
    }
    return webroot;
}


const getSettings = async () => {
    const webroot = getWebroot();
    const urls = {
        webroot: webroot,
        api: `https://api.${webroot}`,
        cf: `https://${webroot}`,
    };
    const r = await fetch(`${urls.api}/`, {method: 'GET'});
    const cognitoSettings = await r.json();
    urls['login'] = `https://auth.${urls.webroot}/login?client_id=${cognitoSettings.cognito_client_id}&response_type=token&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&redirect_uri=${encodeURIComponent(urls.cf + '/')}`;
    return {
        cognito: {
             clientId: cognitoSettings.cognito_client_id,
            userPoolId: cognitoSettings.cognito_user_pool_id
        },
        urls: urls
    };
}

export const settingsContext = createContext(null);
export const SettingsProvider = ({children}) => {
    const [settings, setSettings] = useState(null);
    useEffect(() => {
        (async () => {
            const fetched = await getSettings();
            setSettings(fetched);
        })();
    }, []);
    const imgContents = (
        <View style={{flex: 1, justifyContent: "center", alignItems: "center"}}>
          <Image style={{width: '50%', height: '50%'}} resizeMode='contain' source={require('/assets/logo_name_and_logo.png')}></Image>
          <ActivityIndicator style={{paddingTop: 80}} size='large'/>
        </View>
      )
    return (
        <settingsContext.Provider value={settings}>
            {settings && children || imgContents}
        </settingsContext.Provider>
    )
}
