import { ScrollView, StyleSheet, View } from "react-native";
import { ActivityIndicator, Button, Dialog, Modal, Text } from "react-native-paper";
import { useAppTheme } from "./Themes";
import { useState } from "react";

export const strip_id = (id) => {
    const real_id = id.split('#')[1];
    return real_id
}

export const checksum = (s) => {
    var chk = 0x12345678;
    var len = s.length;
    for (var i = 0; i < len; i++) {
        chk += (s.charCodeAt(i) * (i + 1));
    }

    return (chk & 0xffffffff).toString(16);
}

export const AdminPanel = ({ children }) => {
    const styles = getStyles();
    return (
        <ScrollView style={styles.main} showsVerticalScrollIndicator={false} showsHorizontalScrollIndicator={false}>
            {children}
        </ScrollView>
    )
}

type EditModalProps = {
    visible: boolean,
    warning?: string,
    children?: React.ReactNode,
    cancelAction: () => void,
    saveAction: () => void,
    onHide: () => void,
}
export const EditModal = (props: EditModalProps) => {
    const styles = getStyles();
    const cancelAction = () => {
        props.cancelAction();
        props.onHide();
    }

    const saveAction = () => {
        props.saveAction()
        props.onHide();
    }
    return (
        <Modal
            visible={props.visible}
            onDismiss={cancelAction}
            contentContainerStyle={styles.modal}
        >
            <View style={{ paddingBottom: 30 }}>
                {props.children && props.children}
            </View>
            {
                props.warning && (
                    <View style={{ paddingBottom: 30 }}>
                        <Text variant="bodyMedium">
                            {props.warning}
                        </Text>
                    </View>
                )   
            }
            <View style={{ flexDirection: "row" }}>
                <Button onPress={saveAction}>Save</Button>
                <Button onPress={cancelAction}>Close</Button>
            </View>
        </Modal>
    )
}

type DeleteProps = {
    visible: boolean,
    title: string,
    verifyMessage?: string,
    deleteCallback: () => void,
    onHide: () => void,
}
export const DeleteDialog = (props: DeleteProps) => {
    const yesAction = () => {
        props.deleteCallback();
        props.onHide();
    }
    const noAction = () => {
        props.onHide();
    }
    return (
        <Dialog visible={props.visible} style={{ maxWidth: 350, alignSelf: "center" }}>
            <Dialog.Title>{props.title}</Dialog.Title>
            <Dialog.Content>
                <Text variant="bodySmall">{props.verifyMessage}</Text>
            </Dialog.Content>
            <Dialog.Actions>
                <Button onPress={yesAction}>Yes</Button>
                <Button onPress={noAction}>No</Button>
            </Dialog.Actions>
        </Dialog>
    )
}


export function getStyles() {
    const theme = useAppTheme();
    return StyleSheet.create({
        backgroundContainer: {
            flex: 1,
            backgroundColor: theme.colors.baseBackground,
        },
        main: {
            padding: 20,
            margin: 10,
            height: '90%',
            width: '90%',
            alignSelf: 'center',
            borderRadius: 20,
            backgroundColor: theme.colors.surface
        },
        modal: {
            alignItems: "center",
            justifyContent: "center",
            alignSelf: "center",
            borderRadius: 20,
            backgroundColor: theme.colors.surface,
            padding: 20,
            width: '30%',
        },
        topButtons: { margin: 0, alignSelf: "center" },
    });
}

export const Loading = () => {
    return (
        <View style={{flex: 1, justifyContent: "center", alignItems: "center"}}>
            <ActivityIndicator size='large'/>
        </View>
    )
}