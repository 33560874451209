import { User } from "./User";
import { Auth } from "./Auth";

export class Vocab {
    settings: any
    auth: Auth
    user: User
    idToken: Promise<string>

    constructor(settings) {
        this.settings = settings;
        this.auth = new Auth(this.settings);
        this.user = new User(this.settings);
        this.idToken = this.auth.authUser()
    }

    async getVocabForWeek(week_id) {
        const reqUrl = `${this.settings.urls.api}/weeks/${week_id}/vocab`;
        const vocab = await fetch(reqUrl, {method: 'GET', headers: {Authorization: await this.idToken}});
        const words = await vocab.json();
        return words.vocab;
    }

    async addVocabForWeek(vocabData) {

        const requestBody = {vocab_id: vocabData.vocab_id, course_id: vocabData.course_id};
        const reqUrl = `${this.settings.urls.api}/weeks/${vocabData.week_id}/vocab`;
        const headers = {Authorization: await this.idToken, 'Content-Type': "application/json"};
        await fetch(reqUrl, {method: 'POST', headers: headers, body: JSON.stringify(requestBody)});
    }

    async getVocabCards(){
        const reqUrl = `${this.settings.urls.api}/students/me/cards`;
        const cards = await fetch(reqUrl, {method: 'GET', headers: {Authorization: await this.idToken}});
        const cardData = await cards.json();
        await cardData.cards.forEach(card => {
            card.due = new Date(card.due);
            card.last_review = new Date(card.last_review);
        });
        return await cardData.cards;
    }

    async deleteVocabFromWeek(props) {
        const weekId = props.week_id;
        const vocabId = props.vocab_id;
        const reqUrl = `${this.settings.urls.api}/weeks/${weekId}/vocab/${vocabId}`
        await fetch(reqUrl, {method: 'DELETE', headers: {Authorization: await this.idToken}});
    }

    async createVocab(vocabData) {
        const requestBody = {
            word: vocabData.word,
            gloss: vocabData.gloss,
        };
        const reqUrl = `${this.settings.urls.api}/vocab`
        const headers = {Authorization: await this.idToken, 'Content-Type': "application/json"};
        let newVocab = await fetch(reqUrl, {method: 'POST', headers: headers, body: JSON.stringify(requestBody)});
        if (newVocab.status == 409) {
            const existing = await this.getVocabByWord(requestBody.word);
            return existing;
        }
        return await newVocab.json();
    }

    async getVocabByWord(word) {
        const params = new URLSearchParams({word: word});
        const reqUrl = `${this.settings.urls.api}/vocabByWord?${params}`;
        const headers = {Authorization: await this.idToken, 'Content-Type': "application/json"};
        let vocab = await fetch(reqUrl, {method: 'GET', headers: headers});
        return await vocab.json();
    }

    async updateVocab(vocabData) {
        const requestBody = {vocab_id: vocabData.vocab_id}
        const valid_keys = ["gloss", "word"];
        valid_keys.forEach((key) => {
            if (key in vocabData) requestBody[key] = vocabData[key];
        });

        const reqUrl = `${this.settings.urls.api}/vocab/${vocabData.vocab_id}`
        const headers = {Authorization: await this.idToken, 'Content-Type': "application/json"};
        const response = await fetch(reqUrl, {method: 'PUT', headers: headers, body: JSON.stringify(requestBody)});
        return await response.json();
    }

    async deleteVocab(vocabData) {

        const reqUrl = `${this.settings.urls.api}/vocab/${vocabData.vocab_id}`
        const headers = {Authorization: await this.idToken, 'Content-Type': "application/json"};
        await fetch(reqUrl, {method: 'DELETE', headers: headers});
    }

    async updateCard(card){
        const requestBody = {
            student_id: card.student_id,
            vocab_id: card.vocab_id,
            weeks: card.weeks,
            due: card.due.getTime(),
            stability: card.stability,
            difficulty: card.difficulty,
            elapsed_days: card.elapsed_days,
            scheduled_days: card.scheduled_days,
            reps: card.reps,
            lapses: card.lapses,
            state: card.state,
            last_review: card.last_review.getTime(),
        };
    
        const reqUrl = `${this.settings.urls.api}/students/me/cards/${card.vocab_id}`;
        const headers = {Authorization: await this.idToken, 'Content-Type': "application/json"};
        await fetch(reqUrl, {method: 'PUT', headers: headers, body: JSON.stringify(requestBody)})
    }

    async paginate(limit, last_uuid) {
        const params = new URLSearchParams({
            limit: limit, 
            last_uuid: last_uuid
        });
        const reqUrl = `${this.settings.urls.api}/vocab?${params}`;
        const headers = {Authorization: await this.idToken, 'Content-Type': "application/json"};
        const results = await fetch(reqUrl, {method: 'GET', headers: headers});
        return await results.json();
    }
}
