import { Auth } from "./Auth";


export class User {
    settings: any   
    auth: Auth 
    idToken: Promise<string>

    constructor(settings) {
        this.settings = settings
        this.auth = new Auth(this.settings);
        this.idToken = (async () => { return await this.auth.authUser(); })()
    }

    async aboutMe(){
        const aboutMe = await fetch(`${this.settings.urls.api}/students/me`, {method: 'GET', headers: {Authorization: await this.idToken}});
        return await aboutMe.json();
    }

    async getUserClasses(id) {
        const myClasses = await fetch(`${this.settings.urls.api}/students/${id}/enrollments`, {method: 'GET', headers: {Authorization: await this.idToken}});
        const courses = await myClasses.json();
        return courses;
    }

    async updateWeeksEnabled(weekId, enabled) {
        const requestBody = {
            week_id: weekId,
            enabled: enabled
        };
        const reqUrl = `${this.settings.urls.api}/students/me/weeksEnabled`;
        const headers = {Authorization: await this.idToken, 'Content-Type': "application/json"};
        const newWeeksEnabled = await fetch(reqUrl, {method: 'PUT', headers: headers, body: JSON.stringify(requestBody)});
        return await newWeeksEnabled.json();
    }

    async getAllUsers() {
        const reqUrl = `${this.settings.urls.api}/students/`;
        const headers = {Authorization: await this.idToken, 'Content-Type': "application/json"};
        const allUsers = await fetch(reqUrl, {method: 'GET', headers: headers});
        return await allUsers.json();
    }
}