import clone from "just-clone";
import { useContext, useEffect, useState } from "react";
import { View, Text } from "react-native"
import { Button, List, TextInput, Portal, Divider, Checkbox } from "react-native-paper"
import { Course } from "../Data/Course";
import { settingsContext } from "../Data/Settings";
import { Week } from "../Data/Week";
import { WeekView } from "./AdminWeek";
import { DeleteDialog, EditModal, getStyles } from "./Lib";

const CourseCreateView = (props) => {
    const [courseName, setCourseName] = useState('');
    const [coursePublic, setCoursePublic] = useState(false);

    const cancelAction = () => {
        setCourseName('');
        setCoursePublic(false);
    }

    const createAction = () => {
        const created = {
            name: courseName,
            public: coursePublic
        }
        props.createCallback(created);
    }
    return (
        <EditModal visible={props.visible} cancelAction={cancelAction} saveAction={createAction} onHide={props.hideCallback}>
                <TextInput
                    label="Course name"
                    value={courseName}
                    onChangeText={(text => setCourseName(text))}
                />
                <View style={{flexDirection: "row", paddingTop: 30, alignItems: "center"}}>
                    <Checkbox 
                        status={coursePublic ? 'checked' : 'unchecked'}
                        onPress={() => {
                            setCoursePublic(!coursePublic);
                        }}
                    />
                    <Text>Public Course</Text>
                </View>
        </EditModal>
    )
}

const CourseEditView = (props) => {
    const styles = getStyles();
    const [courseName, setCourseName] = useState(props.name)
    const [coursePublic, setCoursePublic] = useState(props.public)

    const cancelAction = () => {
        setCourseName(props.name);
        setCoursePublic(props.public);
    }

    const updateAction = () => {
        const updated = {
            course_id: props.course_id,
            name: courseName,
            public: coursePublic
        }
        props.updateCallback(updated);
    }
    return (
        <EditModal visible={props.visible} cancelAction={cancelAction} saveAction={updateAction} onHide={props.hideCallback}>
            <TextInput
                label="Course name"
                value={courseName}
                onChangeText={(text => setCourseName(text))}
            />
            <View style={{flexDirection: "row", paddingTop: 30, alignItems: "center"}}>
                <Checkbox 
                    status={coursePublic ? 'checked' : 'unchecked'}
                    onPress={() => {
                        setCoursePublic(!coursePublic);
                    }}
                />
                <Text>Public Course</Text>
                
            </View>
        </EditModal>
    )
}
const CourseDeleteView = (props) => {
    const deleteAction = () => {
        const courseData = {
            course_id: props.course_id,
            enabled: false
        }
        props.deleteCallback(courseData);
    }
    return (
        <DeleteDialog 
            visible={props.visible} 
            title="Are you sure you want to delete this course?" 
            deleteCallback={deleteAction} 
            onHide={props.hideCallback}
        />
    )
}

const WeekCreateView = (props) => {
    const styles = getStyles();
    const [topic, setTopic] = useState('')

    const cancelAction = () => {
        setTopic('');
    }

    const createAction = () => {
        const created = {
            course_id: props.course_id,
            order: props.order,
            topic: topic
        }
        props.createCallback(created);
    }
    return (
        <EditModal visible={props.visible} cancelAction={cancelAction} saveAction={createAction} onHide={props.hideCallback}>
            <TextInput
                label="Topic"
                value={topic}
                onChangeText={(text => setTopic(text))}
            />
        </EditModal>
    )
}

const CourseView = (props) => {
    const styles = getStyles();
    const settings = useContext(settingsContext);

    const [expanded, setExpanded] = useState(false);
    const [weeks, setWeeks] = useState<Map<String, any>>(new Map());
    const [editVisible, setEditVisible] = useState(false);
    const showEdit = () => setEditVisible(true);
    const hideEdit = () => setEditVisible(false);
    const [deleteVisible, setDeleteVisible] = useState(false);
    const showDelete = () => setDeleteVisible(true);
    const hideDelete = () => setDeleteVisible(false);
    const [createWeekVisible, setCreateWeekVisible] = useState(false);
    const showCreateWeek = () => setCreateWeekVisible(true);
    const hideCreateWeek = () => setCreateWeekVisible(false);
    const handlePress = () => {
        setExpanded(!expanded);
    }

    useEffect(() => {
        const getWeeks = async () => {
            const week = new Week(settings);
            const fetched = await week.getWeeksForCourse(props.course_id);
            const newWeeks: Map<String, any> = new Map();
            fetched.forEach((f) => {
                newWeeks.set(f.week_id, f);
            })
            setWeeks(newWeeks);
        }
        getWeeks();
    }, [])
    
    const createWeek = async(weekData) => {
        const week = new Week(settings);
        const newWeek = await week.createWeek(weekData);
        const newWeeks = clone(weeks);
        newWeeks.set(newWeek.week_id, newWeek);
        setWeeks(newWeeks);
    };

    const updateWeek = async(weekData) => {
        const week = new Week(settings);
        const newWeek = await week.updateWeek(weekData);
        const newWeeks = clone(weeks);
        newWeeks.set(newWeek.week_id, newWeek);
        setWeeks(newWeeks);
    }

    const deleteWeek = async (weekData) => {
        const week = new Week(settings);
        week.deleteWeek(weekData);
        const newWeeks = clone(weeks);
        newWeeks.delete(weekData.week_id);
        setWeeks(newWeeks);
    }

    const WeekList = () => {
        const weekList = [];
        weeks.forEach((w) => {
            weekList.push(<WeekView key={w.week_id} {...w} updateCallback={updateWeek} deleteCallback={deleteWeek}/>);
        });
        return weekList;
    }
    let courseTitle = props.name;
    if (props.public === true) courseTitle += " (public)";
    return (
            <List.Accordion
                title={courseTitle}
                left={props => <List.Icon style={{margin: 0}} {...props} icon="book-outline" />}
                expanded={expanded}
                onPress={handlePress}
                style={{flex: .9, minWidth: '90%', margin: 0, padding: 0}}
            >
                <View style={{flex: 1}}>
                    <Portal>
                        <CourseEditView {...props} visible={editVisible} hideCallback={hideEdit} updateCallback={props.updateCallback} />
                        <CourseDeleteView {...props} visible={deleteVisible} hideCallback={hideDelete} deleteCallback={props.deleteCallback} />
                    </Portal>
                    <View style={{flex:1}}>
                        <View style={{margin: 0, flexDirection: "row" }}>
                            <Button {...props} style={styles.topButtons} size={16} mode="text" icon="file-document-edit-outline" onPress={showEdit}>Edit Course</Button>
                            <Button {...props} style={styles.topButtons} size={16} mode="text" icon="delete-forever-outline" onPress={showDelete}>Delete Course</Button>
                            <Button {...props} style={styles.topButtons} size={16} mode="text" icon="calendar-plus" onPress={showCreateWeek}>New Week</Button>
                        </View>
                        <Divider />
                        <View style={{padding: 10}}>
                            <Portal>
                                <WeekCreateView {...props} visible={createWeekVisible} hideCallback={hideCreateWeek} createCallback={createWeek} />
                            </Portal>
                            <List.Section>
                                <WeekList />
                            </List.Section>
                        </View>
                    </View>

                </View>
            </List.Accordion>
    )
}

export const AdminCourse = (props) => {
    const styles = getStyles();
    const settings = useContext(settingsContext);

    const [courses, setCourses] = useState<Map<String, any>>(new Map());

    useEffect(() => {
        const getCourses = async () => {
            const course = new Course(settings);
            const currentCourses = await course.getCourses()
            const courseMap: Map<String, any> = new Map();
            currentCourses.forEach((c) => {
                courseMap.set(c.course_id, c);
            });
            setCourses(courseMap);
        }
        getCourses();
    }, [])

    const createCourse = async(courseData) => {
        const course = new Course(settings);
        const newCourse = await course.createCourse(courseData);
        const newCourses = clone(courses);
        newCourses.set(newCourse.course_id, newCourse);
        setCourses(newCourses);
    }
    
    const updateCourse = async (courseData) => {
        const course = new Course(settings);
        const updatedCourse = await course.updateCourse(courseData);
        const newCourses = clone(courses);
        newCourses.set(updatedCourse.course_id, updatedCourse);
        setCourses(newCourses);
    };

    const disableCourse = async(courseData) => {
        const course = new Course(settings);
        await course.updateCourse(courseData);
        const newCourses = clone(courses);
        newCourses.delete(courseData.course_id);
        setCourses(newCourses);
    };

    const CourseList = () => {
        const cs = [];
        courses.forEach((c) => {
            cs.push(<CourseView key={c.course_id} {...c} createCallback={createCourse} updateCallback={updateCourse} deleteCallback={disableCourse} />);
        });
        return cs;
    }

    const [createVisible, setCreateVisible] = useState(false);
    const showCreate = () => setCreateVisible(true);
    const hideCreate = () => setCreateVisible(false);
    
    return (
        <List.Section>
             <Portal>
                <CourseCreateView {...props} visible={createVisible} hideCallback={hideCreate} createCallback={createCourse} />
            </Portal>
            <Button {...props} style={[styles.topButtons, {alignSelf: "flex-start"}]} size={16} mode="text" icon="book-plus-outline" onPress={showCreate}>New Course</Button>
            <CourseList />
        </List.Section>
    )
}