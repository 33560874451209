import { useContext, useEffect, useState } from "react";
import { View } from "react-native"
import { Course } from "../Data/Course";
import { settingsContext } from "../Data/Settings";
import { Button, Checkbox, IconButton, List, Portal } from "react-native-paper";
import { User } from "../Data/User";
import { DeleteDialog, EditModal, Loading, getStyles } from "./Lib";
import clone from "just-clone";

const addToCollection = (student, collection) => {
    const dupes = clone(collection);
    let already = false;
    for (let i = 0; i < dupes.length; i++) {
        if (dupes[i].user_id == student.user_id) {
            already = true;
            break;
        }
    }
    if (!already) {
        dupes.push(student)
    }
    return dupes;
}

const removeFromCollection = (student, collection) => {
    let dupes = clone(collection);
    for (let i = 0; i < dupes.length; i++) {
        if (dupes[i].user_id == student.user_id) {
            dupes.splice(i, 1);
            break;
        }
    }
    return dupes;
}

const EnrollStudentView = (props) => {
    const [students, setStudents] = useState(null);
    const [notStudents, setNotStudents] = useState(null);

    useEffect(() => {
        setStudents(props.students);
        setNotStudents(props.notStudents);
    }, [props])

    const enrollStudent = (student) => {
        const newStudents = addToCollection(student, students);
        setStudents(newStudents);
        const newNotStudents = removeFromCollection(student, notStudents);
        setNotStudents(newNotStudents);
    }

    const unenrollStudent = (student) => {
        const newStudents = removeFromCollection(student, students);
        setStudents(newStudents);
        const newNotStudents = addToCollection(student, notStudents);
        setNotStudents(newNotStudents);
    }

    const cancelAction = () => {
        setStudents(props.students);
        setNotStudents(props.notStudents);
        props.onHide();
    }

    const saveAction = () => {
        props.onStudentChange(students, notStudents);
    }

    const toggleEnroll = ({student, enrolled}) => {
        if (enrolled === true) {
            enrollStudent(student);
        } else {
            unenrollStudent(student);
        }
    }

    const results = [];
    props.notStudents.forEach((student) => {
        const lineItem = <EnrollmentStudentCheck key={student.user_id} student={student} onChange={toggleEnroll}/>
        results.push(lineItem);
    })
    const unenrolled = <List.Section>{results}</List.Section>
    return (
        <EditModal visible={props.visible} cancelAction={cancelAction} saveAction={saveAction} onHide={props.onHide}>
            {unenrolled}
        </EditModal>
    )
}

const EnrollmentStudentCheck = (props) => {
    const [checked, setChecked] = useState(false);
    const onCheck = () => {
        const enrolled = !checked;
        setChecked(enrolled);
        const student = props.student;
        props.onChange({student, enrolled});
    }
    return (
        <Checkbox.Item 
            label={`${props.student.given_name} ${props.student.family_name}`} 
            status={checked ? 'checked' : 'unchecked'} 
            onPress={onCheck}
        />
    )
}

const EnrollmentStudent = (props) => {
    const styles = getStyles();
    const [visible, setVisible] = useState(false);
    const iconButton = <IconButton style={styles.topButtons} size={16} icon="cancel" onPress={() => {setVisible(true)}}></IconButton>
    return (
        <View style={{flexDirection: "row"}}>
            <Portal>
                <DeleteDialog 
                    visible={visible} 
                    deleteCallback={() => {props.unenrollAction(props.student)}} 
                    onHide={() => {setVisible(false)}} 
                    title={`Are you sure you want to unenroll ${props.student.given_name} ${props.student.family_name} from this course?`}
                />
            </Portal>
            <List.Item 
                style={{paddingLeft: 40}} 
                title={`${props.student.given_name} ${props.student.family_name}`} 
                description={props.student.email} 
                left={props => iconButton}/>
        </View>
    )
};

const EnrollmentCourse = (props) => {
    const styles = getStyles();
    const settings = useContext(settingsContext);

    const [editVisible, setEditVisible] = useState(false);
    const showEdit = () => setEditVisible(true);
    const hideEdit = () => setEditVisible(false);
    const [expanded, setExpanded] = useState(false);
    const handlePress = () => {
        setExpanded(!expanded);
    }

    const [students, setStudents] = useState([]);
    const [notStudents, setNotStudents] = useState([]);
    useEffect(() => {
        const getStudents = async () => {
            const course = new Course(settings);
            const user = new User(settings);
            const fetched = await course.getStudents(props.course_id);
            const unenrolled = [];
            props.allStudents.forEach((s) => {
                let enrolled = false;
                s.courses.forEach((c) => {
                    if (c.course_id == props.course_id) {
                        enrolled = true;
                    }
                });
                if (!enrolled) unenrolled.push(s);
            });
            setStudents(fetched.students);
            setNotStudents(unenrolled);
        };
        getStudents();

    }, [])

    const unenrollStudent = (student) => {
        const newStudents = removeFromCollection(student, students); 
        const newNotStudents = addToCollection(student, notStudents);
        onStudentChange(newStudents, newNotStudents);
    }
    
    const onStudentChange = (newStudents, newNotStudents) => {
        const course = new Course(settings);
        const studentMap = new Map();
        students.forEach((s) => studentMap.set(s.user_id, s));
        const notStudentMap = new Map();
        notStudents.forEach((s) => notStudentMap.set(s.user_id, s));
        newStudents.forEach((s) => {
            if (!studentMap.has(s.user_id)) {
                course.manageEnrollment(props.course_id, s.user_id, "enroll");
            }
        })
        newNotStudents.forEach((s) => {
            if (!notStudentMap.has(s.user_id)) {
                course.manageEnrollment(props.course_id, s.user_id, "unenroll");
            }
        })
        setStudents(newStudents);
        setNotStudents(newNotStudents);    
    }

    return (
        <List.Accordion
            title={props.name}
            left={props => <List.Icon style={{margin: 0}} {...props} icon="book-outline" />}
            expanded={expanded}
            onPress={handlePress}
            style={{flex: .9, minWidth: '90%', margin: 0, padding: 0}}
        >    
        <Portal>
            <EnrollStudentView visible={editVisible} onHide={hideEdit} students={students} notStudents={notStudents} onStudentChange={onStudentChange}/>
        </Portal>
        <Button style={[styles.topButtons, {alignSelf: "flex-start"}]} onPress={showEdit} icon="account-plus">Enroll Student</Button>
        {Array.from(students.values()).map((s: any) => {return <EnrollmentStudent student={s} key={s.user_id} unenrollAction={unenrollStudent}/>})}
        </List.Accordion>
    )
}

export const Enrollment = () => {
    const settings = useContext(settingsContext);
    const [courses, setCourses] = useState<Map<String, any>>(new Map());
    const [allStudents, setAllStudents] = useState(null);
    useEffect(() => {
        const getCourses = async () => {
            const course = new Course(settings);
            const fetchedCourses = await course.getCourses()
            const newCourses: Map<String, any> = new Map();
            for (const c of fetchedCourses){
                const courseId = c.course_id;
                newCourses.set(courseId, c);
            }
            setCourses(newCourses);
        }
        const getStudents = async () => {
            const user = new User(settings);
            const students = await user.getAllUsers();
            setAllStudents(students);
        }
        getCourses();
        getStudents();
    }, [])

    if (!allStudents) {
        return <Loading />
    }

    return (
        <View>
            {allStudents && Array.from(courses.values()).map((course) => {return <EnrollmentCourse key={course.course_id} {...course} allStudents={allStudents}/>})}
        </View>
    );
}