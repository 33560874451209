import React, { useContext, useEffect, useState } from 'react';
import { Appbar, Menu, List, Text, Avatar, Switch, Checkbox, Divider } from 'react-native-paper';
import { Image, Linking, View } from 'react-native';
import clone from 'just-clone';
import { useAppTheme } from './Themes';
import { settingsContext } from '../Data/Settings';

import { User } from '../Data/User';

const icon = (props) => {
    return <Image source={require('../assets/logo.png')} style={{width: props.size, height: props.size}} key="topleftimage"/>
};

export function NavBarBase(props) {
    return (
        <Appbar.Header>
            <Appbar.Action key='menu_home' animated={false} icon={icon} onPress={() => {Linking.openURL('https://kairosclassroom.com');}}/>
            <Appbar.Content title="Kairos Classroom" />
            {props.userInfo?.is_admin == true && <Appbar.Action key="adminize" icon="shield-crown" onPress={() => {props.navigation.navigate("Admin")}} />}
            {props.children}
        </Appbar.Header>
    )
}

export function NavBarWithCourses(props){
    const settings = useContext(settingsContext);
    const [ready, setReady] = useState(false);
    const [userInfo, setUserInfo] = useState(null);
    const [courses, setCourses] = useState(null);

    let intervalId;
    useEffect(() => {
        const getUserInfo = async () => {

            const user = new User(settings);
            const uInfo = await user.aboutMe();
            setUserInfo(uInfo);
            if (!uInfo.is_active) return;

            const courses = await user.getUserClasses(uInfo.id);
            const weeks = []
            await courses.enrolled.forEach((course) => {
                course.weeks.forEach(week => {
                    if (uInfo.weeks_enabled.includes(week.week_id)) weeks.push(week.week_id);
                  });
            });
            setCourses(courses.enrolled);
            props.setWeeksCallback(weeks);
            setReady(true);
            clearInterval(intervalId);
        };
        intervalId = setInterval(getUserInfo, 5000);
        getUserInfo();
        return () => clearInterval(intervalId);
    },[])

    const [visible, setVisible] = useState(false);

    const openMenu = () => {setVisible(true);};
    const closeMenu = () => {setVisible(false);};

    
    const [expanded, setExpanded] = useState({});

    const handlePress = (key) => {
        const current_state = {...expanded};
        current_state[key] = !current_state[key]
        setExpanded(current_state);
    }
    
    const theme = useAppTheme();

    const getAvatarField = () => {
        let avatarField;
        if ('avatar' in userInfo) {
            avatarField = <Avatar.Image source={userInfo.avatar}/>;
        } else {
            avatarField = <Avatar.Text label={`${userInfo.first_name.charAt(0)}${userInfo.last_name.charAt(0)}`.toUpperCase()}/>
        }
        return avatarField;
    }

    const WeekToggle = (props) => {
        const [isEnabled, setIsEnabled] = useState(props.isEnabled);

        const onToggleSwitch = () => {
            const enabledToggle = !isEnabled;
            const user = new User(settings);
            setIsEnabled(enabledToggle);
            props.weekCallback(props.weekId);

            const newCourses = clone(courses);
            newCourses.forEach((course) => {
                if (course.course_id !== props.courseId) return;
                course.weeks.forEach((week) => {
                    if (week.week_id !== props.weekId) return;
                    week.is_active = enabledToggle;
                    user.updateWeeksEnabled(week.week_id, enabledToggle );
                });
                setCourses(newCourses);
            });
        }

        return <Switch style={{alignSelf: "center"}} value={isEnabled} onValueChange={onToggleSwitch} />
    }
    const WeekItem = (props) => {
        const week = props.week;
        const course = props.course;
        return (
            <List.Item 
                style={{marginLeft: 10, flex: 1}}  
                title={week.topic} 
                description={`Week ${week.order}`} 
                left={props => {
                    return (
                        <View style={{flexDirection: "row", flex: .25, paddingRight: 10}}>
                            <WeekToggle {...props} 
                                weekId={week.week_id} 
                                courseId={course.course_id} 
                                isEnabled={week.is_active} 
                                weekCallback={weekCallback}
                            /> 
                        </View>
                    )
                }}
            />
        );
    };    

    const weekCallback = props.weekCallback;
    const getClassesAccordion = () => {
        const listAccordion = courses.map((course) => {
            const key = `${course.course_id}::${course.section_id}`;
            return (
                <List.Accordion
                    title={course.name}
                    key={key}
                    description={course.timeslot}
                    left={props => <List.Icon {...props} icon="cards-variant" />}
                    expanded={expanded[key]}
                    onPress={() => handlePress(key)}>
                        {course.weeks.map((week) => <WeekItem key={week.week_id} week={week} course={course} />)}
                </List.Accordion>
            )
        });
        return listAccordion;
    }   

    return (
        <NavBarBase userInfo={userInfo} navigation={props.navigation}>
            {
                ready && 
                <Menu
                    style={{width: '25%'}}
                    visible={visible}
                    onDismiss={closeMenu}
                    anchorPosition='bottom'
                    anchor={<Appbar.Action key='menu_settings' icon='cog' onPress={openMenu} iconColor={theme.colors.onSurface} />}>
                        <View style={{flex:1}}>
                            <View style={{flexDirection: 'row', flex: 1}}><Text style={{padding: 15, paddingTop: 0, paddingRight: 5}} variant="headlineMedium">{`${userInfo.first_name} ${userInfo.last_name}`}</Text></View>
                            <View style={{flexDirection: 'row', flex: 1, paddingHorizontal: 15, paddingBottom: 15}}>
                                {getAvatarField()}
                                <View style={{flexDirection: 'column', marginLeft: 20, justifyContent: 'center'}}>
                                    <Text variant="bodyMedium">@{userInfo.username}</Text>
                                    <Text variant="bodyMedium">{userInfo.email}</Text>
                                </View>

                            </View>
                        </View>
                        <Divider />
                        <List.Section title="My Classes">
                            {getClassesAccordion()}
                        </List.Section>
                </Menu>
            }               
        </NavBarBase>
    )

};