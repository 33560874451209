import { Auth } from "./Auth";

export class Course {
    settings: any
    auth: Auth
    idToken: Promise<string>

    constructor(settings) {
        this.settings = settings;
        this.auth = new Auth(this.settings);
        this.idToken = this.auth.authUser()
    }

    async getCourses() {
        const courses = await fetch(`${this.settings.urls.api}/course`, {method: 'GET', headers: {Authorization: await this.idToken}});
        const courseJs = await courses.json();
        console.log(courseJs)
        return courseJs.courses
    }

    async getStudents(courseId) {
        const reqUrl = `${this.settings.urls.api}/course/${courseId}/students` 
        const headers = {Authorization: await this.idToken, 'Content-Type': "application/json"}; 
        const resp = await (await fetch(reqUrl, {method: 'GET', headers: headers})).json()
        return await resp
    }

    async createCourse(course) {
        const requestBody = {
            name: course.name,
            public: course.public
        };
        const reqUrl = `${this.settings.urls.api}/course`;
        const headers = {Authorization: await this.idToken, 'Content-Type': "application/json"};
        const updatedCourse = await fetch(reqUrl, {method: 'POST', headers: headers, body: JSON.stringify(requestBody)})
        return await updatedCourse.json();
    }

    async updateCourse(course) {
        const courseId = course.course_id;
        const requestBody = {};
        const validKeys = ['name', 'enabled', 'public'];
        validKeys.forEach((key) => {
            if (key in course) requestBody[key] = course[key];
        });
        const reqUrl = `${this.settings.urls.api}/course/${courseId}`;
        const headers = {Authorization: await this.idToken, 'Content-Type': "application/json"};
        const updatedCourse = await fetch(reqUrl, {method: 'PUT', headers: headers, body: JSON.stringify(requestBody)})
        return await updatedCourse.json();
    }

    async manageEnrollment(courseId, userId, enroll) {
        const requestBody = {"action": enroll}
        const reqUrl = `${this.settings.urls.api}/course/${courseId}/students/${userId}`
        const headers = {Authorization: await this.idToken, 'Content-Type': "application/json"};
        await fetch(reqUrl, {method: 'POST', headers: headers, body: JSON.stringify(requestBody)});
    }
}